import React, { useEffect, useState } from "react";
import { useAccountState } from "@state/sustentus";
import { Loading } from "@ui/sustentus";

const AuthInitializer = ({ children }) => {
  const { restoreSessionFromRedis, state } = useAccountState();
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  useEffect(() => {
    const checkAuth = async () => {
      if (!state.isAuth) {
        try {
          // Restore session from Redis using the token
          await restoreSessionFromRedis("vendor");
        } catch (error) {
          console.error("Failed to restore session:", error);
        }
      }

      // Once session is restored or redirect happens, stop loading
      setIsLoading(false);
    };

    checkAuth();
  }, []);

  // Show a loading spinner or placeholder while loading
  if (isLoading) {
    return <Loading isTrue={isLoading} />;
  }

  return <>{children}</>;
};

export default AuthInitializer;
